
  import {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxLookup, 
    DxPaging,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxGroupPanel,
    DxGrouping,
    DxSorting,
    DxColumnChooser,
    DxButton,
  } from 'devextreme-vue/data-grid';
  import { DxToast } from 'devextreme-vue/toast';
  import DxGallery from "devextreme-vue/gallery";
  
  import QuoteService from '../services/quote-service';
  import ClientService from '../services/client-service';
  import CompanyService from '../services/company-service';
  import FaultReportService from '../services/fault-report-service';
  
  export default {
    components: {
      DxDataGrid,
      DxEditing,
      DxColumn,
      DxPaging,
      DxLookup,
      DxFilterRow,
      DxSearchPanel,
      DxScrolling,
      DxPager,
      DxSelection,
      DxToast,
      DxGroupPanel,
      DxGrouping,
      DxSorting,
      DxColumnChooser,
      DxGallery,
      DxButton,
    },
    data() {
      return {
        selectedRowKeys: [],
        reportefallas: null,      
        dataSourceQuotes: null,
        dataSourceFaultReportFiles:null,
        dataSourceClients: null,
        dataSourceCompanies: null,
        allowedPageSizes: [5, 10, 20, 50, 'all'],
        showInfo: true,
        showNavButtons: true,
        isVisible: false,
        message: '',
        type: 'info',
        popupOptions: {
          title: 'Reporte de Falla',
          showTitle: true,
          width: 800,
          height: 650,
        },
        mode: 'select',
        autoExpandAll: true,
        titulo_principal: null,
        descripcion_principal: null,
        equipo: null,
        folio: null,
        cliente: null,
        clave_empresa: null,
        estatus: null,
        tipo_falla: null,
        fecha_falla: null,
        fecha_reporte: null,
        reporte_servicio: '',
        cotizacion: '',
      };
    },
    created() {
      this.quoteService = new QuoteService();
      this.companyService = new CompanyService();
      this.clientService = new ClientService();
      this.faultReportService = new FaultReportService();
    },
    mounted() {
      this.companyService.getEmpresas().then(data => this.dataSourceCompanies=data);
      this.clientService.getClientes().then(data => this.dataSourceClients=data);
      this.quoteService.getPendientesPorCotizar().then(data => this.dataSourceQuotes=data);
    },
    methods: {
      logEvent(eventName) {
        this.events.unshift(eventName);
      },
      deleteQuote(e) {
          this.quoteService.deleteSolicitudCotizacion({"origen": e.data.origen, "id": e.data.id}).then(() => {
          this.type = 'success';
          this.message = 'Se ha eliminado la solicitud de cotización';
          this.isVisible = true;
        }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al eliminar la solicitud de cotización';
          this.isVisible = true;
          this.quoteService.getPendientesPorCotizar().then(data => this.dataSourceQuotes=data);
        });
          },
      editFiles(e) { 
        this.faultReportService.getReporteFallaById(e.row.data.id_reporte_falla).then(
          data=> {
            this.dataSourceFaultReportFiles = [];
            data.forEach((element) => {
              var files = {
                url: element.url,
                descripcion: element.descripcion,
                titulo_principal: element.titulo_principal,
                descripcion_principal: element.descripcion_principal,
                equipo: element.equipo,
                folio: element.folio,
                clave_empresa: element.clave_empresa,
                cliente: element.cliente,
                estatus: element.estatus,
                tipo_falla: element.tipo_falla,
                fecha_falla: element.fecha_falla,
                fecha_reporte: element.fecha,
                usuario: element.usuario_iniciales,
                fecha: element.fecha_reg,
                id_tipo_archivo: element.id_tipo_archivo,
                reporte_servicio: element.reporte_servicio,
                cotizacion: element.cotizacion,
              };
              this.titulo_principal = files.titulo_principal;
              this.descripcion_principal = files.descripcion_principal;
              this.equipo = files.equipo;
              this.folio = files.folio;
              this.clave_empresa = files.clave_empresa;
              this.cliente = files.cliente;
              this.tipo_falla = files.tipo_falla;
              this.fecha_falla = files.fecha_falla;
              this.fecha_reporte = files.fecha_reporte;
              this.estatus = files.estatus;
              this.cotizacion = files.cotizacion;
              this.reporte_servicio = files.reporte_servicio;
              this.dataSourceFaultReportFiles.push(files);
            }); 
            var modal = document.getElementById("modalfaultimg"); 
            modal.style.display = "block"; 
          } 
        );
       
      },
      
      closeModalFault() {
        var modal = document.getElementById("modalfaultimg");
        modal.style.display = "none";
      },
  
    },  
  };
  